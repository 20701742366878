page-header {
	// position: fixed;
    // overflow: visible;
    // z-index: 100;
    // background: white;
    // width: 100vw;

	position: sticky;
    overflow: hidden;
    top: 0px;
    z-index: 100;
    background: white;
    background-color: white !important;
}
.first-row {
	background-color: white;
    // height: 80px;
    color: #1c8da8;
    font-size: large;
    font-weight: bold;
	align-items: center;
	border-bottom: 4px solid #91c467;

	a {
		font-size: 1.2rem;
	}
}

.page-header-border {
	border-bottom: 4px solid #91c467;
}


.italy-flag {
	max-height: 3px;
	overflow: hidden;
	background-color: #91c467;
}
.green-bg {
	background-color: transparent;
}
.red-bg {
	background-color: transparent;
}

.last-row {
	display: none;
	// background-color: #AB8f69;
	// height: 60px;
	// color: white;
	// font-size: x-large;
	// font-weight: bold;
    // align-items: center;
}