// Custom Components

// Core variables and mixins
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "core/variables/variables";
@import "~bootstrap/scss/variables";

// Mixins
@import "core/mixins/buttons";
@import "core/mixins/shadows";
@import "core/mixins/utilities";
@import "core/mixins/timeline";

// Overrides user variable
@import "core/variables/app-variables";
// Layouts
@import "core/layouts/content";

// Components
@import "components/utilities";
@import "components/helper";
@import "components/customizer";
@import "components/components";
@import "components/misc";
@import "components/bootstrap-social";
@import "components/responsive";
@import "components/demo";
@import "components/ex-component-avatar";

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/themes/prism-okaidia";
@import '~@angular/cdk/overlay-prebuilt.css';

@import "~@ng-select/ng-select/themes/default.theme.css";

//------------------- below are the files where _palette-variables.scss is included and map-get() is used -------------------
// Layouts
@import "core/layouts/sidebar-content";
@import "components/notification-sidebar";
// Pages
@import "pages/authentication";
@import "components/timeline-vertical";

@import "~jsoneditor/dist/jsoneditor.min.css";
textarea.jsoneditor-text{min-height:350px;}

:host ::ng-deep json-editor,
:host ::ng-deep json-editor .jsoneditor,
:host ::ng-deep json-editor > div,
:host ::ng-deep json-editor jsoneditor-outer {
	// border: #F55252;
	border: thin solid red;
}
.jsoneditor-menu {
	background-color: $primary;
    border-bottom: 1px solid $primary;
}
.jsoneditor-navigation-bar {
	background-color: transparent;
    border-bottom: none;
}
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected {
	background-color: $primary;
}
.jsoneditor {
	// border: thin solid $primary;
	border: 1px solid $border-color;
}
.bordered {
	border: 1px solid lightgrey;
	margin: 15px 0;
	box-shadow: -8px 8px 14px 0 rgb(25 42 70 / 11%);
}

.truncated-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.linked-contents-container {
	display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;
}

.element-preview {
	padding: 10px;
    margin: 15px;
    min-width: 26vw;
    min-height: 13vw;
	max-width: 26vw;
    max-height: 13vw;
	width: 26vw;
    height: 13vw;
}