// This needs review, including colors: https://www.google.com/design/spec/style/typography.html#typography-styles
@import "../core/colors/palette-variables";

blockquote, .blockquote{
  footer, small, .small{
    font-size: 80%;
    padding-left: 0;
  }
}

.blockquote-reverse, blockquote.pull-right{
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid map-get($grey-color, lighten-3);
  border-left: 0;
  text-align: right;
}

h2.title{
  margin-bottom: $spacer * 2;
}

.description,
.card-description,
.footer-big p{
  color: color("gray");
}

.border-color-danger {
	border-color: theme-color("danger") !important;
}
.text-warning {
  color: theme-color("warning") !important;
}
.text-primary {
  color: theme-color("primary") !important;
}
.text-danger {
  color: theme-color("danger") !important;
}
.text-success {
  color: theme-color("success") !important;
}
.text-info {
  color: theme-color("info") !important;
}

//small
.font-small-1{
  font-size: 0.7rem !important;
}
.font-small-2{
  font-size: 0.8rem !important;
}
.font-small-3{
  font-size: 0.9rem !important;
}
//medium
.font-medium-1{
  font-size: 1.1rem !important;
}
.font-medium-2{
  font-size: 1.2rem !important;
}
.font-medium-3{
  font-size: 1.3rem !important;
}
.font-medium-4{
  font-size: 1.4rem !important;
}
.font-medium-5{
  font-size: 1.5rem !important;
}
//large
.font-large-1{
  font-size: 2rem !important;
  line-height: 2rem;
}
.font-large-2{
  font-size: 3rem !important;
  line-height: 2.95rem;
}
.font-large-3{
  font-size: 4rem !important;
  line-height: 3.5rem;
}
.font-large-4{
  font-size: 5rem !important;
  line-height: 4.2rem;
}
.font-large-5{
  font-size: 6rem !important;
  line-height: 5.2rem;
}

// Text styling
// -------------------------

// Font weights

.text-bold-300 {
  @include font-weight(light);
}
.text-bold-400 {
  @include font-weight(book);
}
.text-bold-500 {
  @include font-weight(medium);
}
.text-bold-600 {
  @include font-weight(semi-bold);
}
.text-bold-700 {
  @include font-weight(bold);
}

// Font style
.text-italic {
    font-style: italic;
}
.text-highlight {
    padding: 4px 6px;
}
