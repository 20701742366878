@import 'bootstrap';
@import 'bootstrap-extended';
@import 'colors';
@import 'components';
@import "themes/layout-dark";
@import "core/menu/horizontal-menu";
@import 'overrides';

html {
	box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
	box-sizing: inherit;
  }
  .intro {
	max-width: 1280px;
	margin: 1em auto;
  }
  .table-scroll {
	position: relative;
	width:100%;
	z-index: 1;
	margin: auto;
	overflow: auto;
	height: 80vh;
  }
  .table-scroll table {
	width: 100%;
	// min-width: 1280px;
	margin: auto;
	border-collapse: separate;
	border-spacing: 0;
  }
  .table-wrap {
	position: relative;
  }
  .table-scroll th,
  .table-scroll td {
	// padding: 5px 10px;
	// border: 1px solid #000;
	// background: #fff;
	vertical-align: top;
  }
  .table-scroll thead th {
	background: #ccc;
	// color: #fff;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
  }
  /* safari and ios need the tfoot itself to be position:sticky also */
  .table-scroll tfoot,
  .table-scroll tfoot th,
  .table-scroll tfoot td {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	z-index:4;
  }
  
  th:first-child {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 2;
	background: #ccc;
  }
  td:first-child {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 2;
	background: #ccc;
  }
  thead th:first-child,
  tfoot th:first-child {
	z-index: 5;
  }
  
  